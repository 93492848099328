<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom10 flex-wrap justify-content-space-between">
          <div class="flex flex-wrap">
            <el-input size="medium" v-model="searchData.name" :maxlength="100" placeholder="输入姓名" clearable class="marginRight10 width300 marginBottom10"/>
            <div class="width300 marginRight10 marginBottom10">
              <el-cascader
                  v-model="searchData.region"
                  size="medium"
                  :options="regionData"
                  placeholder="选择省/市"
                  clearable
                  :props="{
                    value: 'id',
                    label: 'name',
                    children: 'children'
                }"
              ></el-cascader>
            </div>
            <el-input size="medium" v-model="searchData.county" :maxlength="100" placeholder="输入区/商圈" clearable class="marginRight10 width300 marginBottom10"/>
            <div class="marginBottom10">
              <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
            </div>
          </div>
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate" class="marginRight10">添加认证经纪人</el-button>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" v-if="row.agentState === 1" size="mini" icon="el-icon-turn-off" @click="handleOperation(row,2)">停用</el-button>
        <el-button type="text" v-if="row.agentState === 2" size="mini" icon="el-icon-open" @click="handleOperation(row,1)">开启</el-button>
        <el-button type="text" size="mini" icon="el-icon-view" @click="handleInfo(row)">详情</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleRemarks(row)">备注</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleVirtualNumber(row)">编辑虚拟号码</el-button>
      </template>

    </avue-crud>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleOne"
        width="30%"
        :before-close="handleCloseOne">
      <div class="flex align-items justify-content-center marginBottom20">
        <el-upload
            class="upload-demo"
            :drag="true"
            action="/api/web/agent/manage/template/import"
            :show-file-list="false"
            :on-success="handleSuccess"
            :before-upload="beforeUpload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="text" size="medium" @click="handleDownload">下载文件模板</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleTwo"
        width="30%"
        :before-close="handleCloseTwo">
      <div v-if="problemState === 2" style="text-align: center">
        <p>部分数据已导入成功</p>
        <p>问题数据请通过下载获取</p>
      </div>
      <div v-if="problemState === 3" style="text-align: center">
        <p>全部导入失败</p>
      </div>
      <div v-if="problemState === 4" style="text-align: center">
        <p>数据为空导入失败</p>
      </div>
      <div class="flex align-items justify-content-center marginBottom10">
        <el-button type="text" size="medium" @click="handleDownloadProblem">下载问题数据</el-button>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="primary" size="medium" @click="handleCloseTwo">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleSuccess"
        width="30%"
        :before-close="handleCloseSuccess">
      <div class="flex align-items justify-content-center marginBottom30">
        <span>导入成功</span>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="primary" size="medium" @click="handleCloseSuccess">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="备注"
        v-if="remarksShow"
        :visible.sync="remarksShow"
        width="35%"
        :before-close="handleCloseRemarks">
      <avue-form ref="form"
                 v-model="formRemarks"
                 :option="formOptionRemarks"
                 @submit="handleSubmitRemarks">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleCloseRemarks">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
    
    <el-dialog
        title="编辑经纪人虚拟号码"
        v-if="virtualNumberShow"
        :visible.sync="virtualNumberShow"
        width="35%"
        :before-close="handleCloseVirtualNumber">
      <avue-form ref="form"
                 v-model="formVirtualNumber"
                 :option="formOptionVirtualNumber"
                 @submit="handleSubmitVirtualNumber">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleCloseVirtualNumber">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { list, stateUpdate, listCity, remarkUpdate, virtualNumberUpdate } from "@/api/agent/manage"
export default {
  name: "index",
  data(){
    return{
      searchData: {
        name: '',
        region: [],
        county: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '经纪人管理',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '用户昵称',
            prop: 'nickname',
            width: 150
          },
          {
            label: '用户手机号',
            prop: 'phone',
            width: 150
          },
          {
            label: '经纪人虚拟号码',
            prop: 'inventPhone',
            width: 150
          },
          {
            label: '用户id',
            prop: 'code',
            width: 150
          },
          {
            label: '用户姓名',
            prop: 'username',
            width: 150
          },
          {
            label: '身份证号',
            prop: 'idCard',
            width: 200
          },
          {
            label: '所在区域',
            prop: 'provinceName',
            formatter: function(row, column, cellValue){
              return cellValue + row.cityName + row.county + row.business;
            },
            width: 200
          },
          {
            label: '相关证书',
            prop: 'certImage',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            width: 200
          },
          {
            label: '个人介绍',
            prop: 'personIntro',
            width: 200
          },
          {
            label: '身份证正反面',
            prop: 'idCardImages',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            width: 200
          },
          {
            label: '经纪人类型',
            prop: 'agentType',
            type: 'radio',
            dicData: [
              {
                label: '认证经纪人',
                value: 1
              },
              {
                label: '全民经纪人',
                value: 2
              }
            ],
            width: 150
          },
          {
            label: '状态',
            prop: 'agentState',
            type: 'radio',
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '停用',
                value: 2
              }
            ],
            width: 150
          },
          {
            label: '备注',
            prop: 'remark',
          }
        ]
      },

      dialogVisibleOne: false,
      dialogVisibleTwo: false,
      problemId: '',  // 导入数据问题ID
      problemState: 1, //状态：1-全部导入成功；2-部分导入成功；3-全部导入失败
      dialogVisibleSuccess: false,


      remarksShow: false,
      formRemarks: {
        id: '',
        remark: ''
      },
      virtualNumberShow: false,
      formVirtualNumber:{
        id: '',
        inventPhone: ''
      },
      formOptionRemarks: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '备注',
            prop: 'remark',
            type: 'textarea',
            maxlength: 1000,
            showWordLimit: true,
            span: 24,
            row: true,
            rules: [
              { required: true, message: '请输入备注', trigger: 'blur' }
            ]
          }
        ]
      },
//编辑虚拟号码
      formOptionVirtualNumber: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '虚拟号码',
            prop: 'inventPhone',
            type: 'input',
            maxlength: 20,
            showWordLimit: true,
            span: 24,
            row: true,
            rules: [
              { required: true, message: '请输入虚拟号码', trigger: 'blur' }
            ]
          }
        ]
      },

      regionData: []
    }
  },
  mounted() {
    this.getListCity();
  },
  methods: {
    getListCity(){
      listCity().then(res => {
        if(res.code === 200){
          this.regionData = res.data;
        }
      })
    },
    onLoad(){
      this.showLoading = true;
      const { region, ...params } = this.searchData;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        provinceId: region[0],
        cityId: region[1],
        ...params
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 搜索
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 添加认证经纪人
    handleCreate(){
      this.dialogVisibleOne = true;
    },
    // 停用
    handleStop(row){
      // console.log('停用')
      stateUpdate({
        id: row.id,
        agentState: 2
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.onLoad();
        }else{
          this.$message.warning(res.msg);
        }
      })
    },
    // 开启
    handleOpen(row){
      console.log('开启')
      stateUpdate({
        id: row.id,
        agentState: 1
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.onLoad();
        }else{
          this.$message.warning(res.msg);
        }
      })
    },
    // 开启/停用
    handleOperation(row,state){
      // console.log('开启',row)
      this.$confirm(state === 1 ? '开启后经纪人的相关功能正常使用，确认开启？' : '停用后有关于认证经纪人的相关功能全部停用，确认停用该经纪人？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // console.log(action)
          done();
          if(action === 'confirm'){
            stateUpdate({
              id: row.id,
              agentState: state
            }).then(res=>{
              if(res.code === 200){
                this.$message.success('操作成功');
                this.onLoad();
              }else{
                this.$message.error(res.msg)
              }
            })
          }
        }
      });
    },
    // 详情
    handleInfo(row){
      this.$router.push({
        path: '/agent/manage/info',
        query: {
          id: row.id
        }
      })
    },
    // 添加认证经纪人
    handleSuccess(res, file) {
      // console.log(1111111,res, file);
      this.dialogVisibleOne = false;
      if(res.code === 200){
        this.problemState = res.data.state;
        setTimeout(()=>{
          if(res.data.state === 1) {
            this.dialogVisibleSuccess = true;
          }else{
            this.problemId = res.data.id;
            this.dialogVisibleTwo = true;
          }
        },500)
      }
    },
    beforeUpload(file) {
      // 文件类型
      const isType = file.type === 'application/vnd.ms-excel';
      const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error('上传文件只能是xls/xlsx格式！');
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 20;
      if (!fileLimit) {
        this.$message.error('上传文件大小不超过20M！');
      }
      return fileType && fileLimit;
    },
    // 下载文件模板
    handleDownload(){
      // console.log('下载文件模板')
      window.open('/api/web/agent/manage/template/export', '_self')
    },
    handleCloseOne(){
      this.dialogVisibleOne = false;
    },
    handleCloseTwo(){
      this.dialogVisibleTwo = false
    },
    // 下载问题数据
    handleDownloadProblem(){
      // console.log('下载问题数据')
      window.open(`/api/web/agent/manage/template/error/export?id=${this.problemId}`, '_self')
    },
    handleCloseSuccess(){
      this.dialogVisibleSuccess = false;
      this.onLoad();
    },

    handleRemarks(row){
      this.formRemarks.id = row.id;
      this.remarksShow = true;
    },
    handleVirtualNumber(row){
      this.formVirtualNumber.id = row.id;
      this.formVirtualNumber.inventPhone = row.inventPhone;
      this.formVirtualNumber = row 
      this.virtualNumberShow = true;
    },
    handleSubmitRemarks(form,done){
      remarkUpdate(form).then(res => {
        if(res.code === 200){
          this.$message.success('备注成功');
          this.remarksShow = false;
          this.formRemarks = {
            id: '',
            remark: ''
          }
          this.onLoad();
        }else{
          this.$message.error(res.msg);
          done()
        }
      }).catch(() => {
        done()
      })
    },
    handleCloseRemarks(){
      this.remarksShow = false;
      this.formRemarks = {
        id: '',
        remark: ''
      }
    },
    //虚拟号码
    handleSubmitVirtualNumber(form,done){
      console.log({...this.virtualNumberRow,...form});
      virtualNumberUpdate({...form}).then(res => {
        if(res.code === 200){
          this.$message.success('修改经纪人虚拟号码成功');
          this.virtualNumberShow = false;
          this.formVirtualNumber = {
            id: '',
            inventPhone: ''
          }
          this.onLoad();
        }else{
          this.$message.error(res.msg);
          done()
        }
      }).catch(() => {
        done()
      })
    },
    handleCloseVirtualNumber(){
      this.virtualNumberShow = false;
      this.formVirtualNumber = {
        id: '',
        inventPhone: ''
      }
    },
  }

}
</script>

<style scoped>

</style>